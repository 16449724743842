require('chosen-js');

function obj(id) {
  return document.getElementById(id);
}

function getScrollBarWidth() {
  let el = document.createElement('div');
  el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
  document.body.appendChild(el);
  let width = el.offsetWidth - el.clientWidth;
  el.remove();
  return width;
}

function makeCollapsable(element, startOpen) {
  var collapsable = element;
  //var imgNode;
  //var spaceNode;
  var dataSticky = $(collapsable).data('toggle-sticky');
  var isSticky = dataSticky == undefined ? false : dataSticky.toString().length > 0;

  var storageKey = function () {
    return 'collapse-open';
  };

  var getStorageValue = function () {
    var raw = localStorage.getItem(storageKey());
    if (raw == undefined || raw.length == 0) {
      raw = '{}';
    }
    var data = JSON.parse(raw);
    var key = $(collapsable).data('toggle-sticky');
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      return data[key];
    } else {
      return;
    }
  };

  var setStorageValue = function (value) {
    var raw = localStorage.getItem(storageKey());
    if (raw == undefined || raw.length == 0) {
      raw = '{}';
    }
    var data = JSON.parse(raw);
    var key = $(collapsable).data('toggle-sticky');
    data[key] = value;
    localStorage.setItem(storageKey(), JSON.stringify(data));
  };

  var setTitle = function ($icon) {
    if ($icon.hasClass('icon-remove')) {
      $icon.attr('title', 'Collapse');
    } else {
      $icon.attr('title', 'Expand');
    }
  };

  // These lines find the first child of collapsable with the specified class
  collapsable.toggle = $('.collapse_toggle:first', collapsable)[0];
  collapsable.content = $('.collapse_content:first', collapsable)[0];
  collapsable.beenOpen = startOpen;

  if (!collapsable.toggle || !collapsable.content) return false;

  if (isSticky && getStorageValue() != undefined) {
    collapsable.beenOpen = getStorageValue();
    startOpen = getStorageValue();
  }

  var $icon = $('<span />');

  $icon.addClass('icon-' + (startOpen ? 'remove' : 'add')).addClass('small');
  setTitle($icon);

  $(collapsable.toggle).css('cursor', 'pointer').prepend($icon);

  collapsable.toggle.href = 'javascript:void(0);';
  $(collapsable.toggle).click(function () {
    if ($(this).data('toggle-speed')) {
      $(collapsable.content).slideToggle($(this).data('toggle-speed'));
    } else {
      $(collapsable.content).slideToggle('fast');
    }
    $icon.toggleClass('icon-remove icon-add');
    setTitle($icon);

    if (isSticky) {
      // $.is(':visible') doesn't work reliably across browsers, so we use this
      // more indirect method of determining the state.
      setStorageValue($icon.hasClass('icon-remove'));
    }

    if (!collapsable.beenOpen) {
      collapsable.beenOpen = true;
    }
  });

  if (!startOpen) {
    $(collapsable.content).hide();
  } else if (startOpen && $(collapsable.content).not(':visible')) {
    $(collapsable.content).show();
  }
}

function fixVertTextContainerSize() {
  // Fix height/width of vertically-oriented (rotated) text
  $('.vert-text:visible').each(function () {
    // We are concerned with three HTML elements
    // 1. The .vert-text container that holds the text content.
    //    The rotate() transform is applied to this element, and this element
    //    is eventually removed from the layout flow.
    var $txtContainer = $(this);
    // 2. The container holding the .vert-text. This is assumed to
    // be a table-cell.
    var $parent = $txtContainer.parent();
    // 3. A spacer element that we will insert to hold space for the content
    //    we have removed from the flow.
    var $spacer = $('<div>');

    // Note the original size of the parent element. We will try to keep this
    // size constant.
    var height =
      $parent.innerHeight() -
      (parseInt($parent.css('padding-top')) + parseInt($parent.css('padding-bottom')));
    var width =
      $parent.innerWidth() -
      (parseInt($parent.css('padding-left')) + parseInt($parent.css('padding-right')));

    // Insert spacer to hold space originally occupied by text.
    $spacer.css({ width: width, height: height });
    $parent.append($spacer);

    // 1. Fix the size of the text container so that it fits within the parent element.
    //    Width and height are intentionally swapped here because their meanings are
    //    swapped due to the rotation. This also causes the text to reflow to fit the space.
    // 2. Remove the text container from page flow (with position: absolute). This
    //   has the effect of allowing the parent to retain its current size. Without this,
    //   the parent will expand horizontally to accommodate the vertical size of the
    //   text, because the text's 'width' now determines that vertical size.
    // 3. Move text down to the right place (translate and transformOrigin).
    $txtContainer.css({
      width: height,
      height: width,
      position: 'absolute',
      transform: 'rotate(-90deg) translate(-' + height + 'px, 0px)',
      transformOrigin: 'top left',
    });

    if ($parent.css('position') == 'static') {
      $parent.css('position', 'relative');
    }
  });

  $('.header-label-tall:visible').each(function () {
    var $txtContainer = $(this);
    var $parent = $txtContainer.parent();
    var height = $txtContainer.height();
    var width = $txtContainer.width();

    $txtContainer.css({
      width: height,
      height: width,
      position: 'absolute',
      bottom: '0px',
      left: '0px',
    });

    $parent.css('height', height);
    $parent.css('width', width);

    if ($parent.css('position') == 'static') {
      $parent.css('position', 'relative');
    }
  });
}

$('html').attr('data-useragent', navigator.userAgent);

var shiftyEyesInterval;

window.monocleDialogClasses = {
  'ui-dialog': 'monocle',
  'ui-dialog-content': 'field-vertical',
  'ui-dialog-buttonset': 'action-buttons',
};

function iframeModalPopup(targetElement, width = 750) {
  var d = $('#iframe-modal-dialog');
  var i = d.find('iframe');
  if (d.length == 0) {
    i = $('<iframe />').css({
      height: '480px',
      width: '100%',
      border: 'none',
    });
    d = $('<div id="iframe-modal-dialog" />');
    d.append(i);
    $('body').append(d);
  }
  i.attr('src', $(targetElement).attr('href'));
  d.dialog({
    modal: true,
    title: $(targetElement).attr('title'),
    width: width,
    classes: window.monocleDialogClasses,
  });
  return false;
}

/* jQuery behaviors */
$(document).ready(function () {
  $(document).on('focus', '.readonly', function () {
    $(this).blur();
  });

  // Show calendar-y date picker
  $('input.date').attr('autocomplete', 'off').datepicker();

  // Show "Loading..." message for slow links and buttons
  $('.slow').click(showLoading);

  var $clickedSubmit;
  $('input[data-disable-with]')
    .closest('form')
    .submit(function () {
      if ($clickedSubmit.attr('data-disable-with') !== undefined) {
        $clickedSubmit.after(
          '<input type="button" value="' +
            $clickedSubmit.attr('data-disable-with') +
            '" disabled />',
        );
        $clickedSubmit.hide();
      }
      $(this)
        .closest('body')
        .find('input:submit:visible')
        .not($clickedSubmit)
        .prop('disabled', true);
    });

  $('input:submit').click(function () {
    $clickedSubmit = $(this);
  });

  // -----------------------------
  // Scold double-clicking of submit buttons
  $('input[type="submit"],input[type="button"]').dblclick(function () {
    alert(
      'Important:\n\n To avoid errors and duplicate data, please do not double-click buttons on this web site.',
    );
    return false;
  });

  // -----------------------------
  // Make text boxes expand into textareas when focused
  $('textarea.expandableText')
    .each(function () {
      this.style.overflow = 'hidden';
    })
    .focus(function () {
      var editorDiv = document.getElementById('editorDiv');
      var editorTextarea = document.getElementById('editorTextarea');
      var smallFieldWidth = this.offsetWidth;
      // var smallFieldLeft = this.offsetLeft;

      this.style.display = 'none';

      editorDiv.parentNode.removeChild(editorDiv);
      this.parentNode.appendChild(editorDiv, this);
      editorDiv.style.left = '';

      editorTextarea.value = this.value;
      editorDiv.style.display = '';

      editorDiv.style.left =
        '' + eval(editorDiv.offsetLeft + smallFieldWidth - editorDiv.offsetWidth) + 'px';
      editorTextarea.focus();
      editorTextarea.style.borderLeft = '3px solid darkgray';
      editorTextarea.style.borderBottom = '3px solid darkgray';
    });

  $('textarea.basic-wysiwyg').each(function () {
    var _$this = $(this),
      rows = parseInt(_$this.attr('rows') || 5) + 2, //The +2 is for the toolbar
      ck = _$this.ckeditor(function () {}, {
        height: rows + 'em',
        skin: 'moono',
        toolbar: [
          ['Bold', 'Italic', 'Underline'],
          ['NumberedList', 'BulletedList'],
          ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
          ['Link', 'Unlink', 'Anchor'],
          ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'],
          '/',
          ['Format', 'FontSize'],
          ['TextColor', 'BGColor'],
          ['RemoveFormat'],
          ['Maximize'],
          ['Undo', 'Redo'],
          ['Scayt'],
        ],
      }).editor;

    ck.on('change', function () {
      _$this.change();
    });
  });

  // Collapsable divs
  $('div.collapsable').each(function () {
    makeCollapsable(this, 1);
  });
  $('div.collapsed').each(function () {
    makeCollapsable(this, 0);
  });
  $('.collapsable-collapseall').click(function () {
    $('.collapse_toggle span.icon-remove').parent().click();
    return false;
  });
  $('.collapsable-expandall').click(function () {
    $('.collapse_toggle span.icon-add').parent().click();
    return false;
  });

  // Numbers requiring commas
  $('td.commas').livequery(function () {
    this.innerHTML = commaFormat(this.innerHTML);
  });
  $('input.commas').livequery(function () {
    this.value = commaFormat(this.value);
  });

  $(function () {
    $('td.commas').each(function () {
      this.innerHTML = commaFormat(this.innerHTML);
    });
    $('input.commas').each(function () {
      this.value = commaFormat(this.value);
    });
  });

  fixVertTextContainerSize();

  $('.secondary-footer.fixed').each(function () {
    var _$this = $(this);
    var $spacer = $('<div class="fixed-footer-spacer" />').height(_$this.outerHeight());

    _$this.before($spacer);

    var scrollHandler = function () {
      if (
        $(this).scrollTop() + $(this).outerHeight() >=
        $('header').height() + $('#main').height() + $('footer').height()
      ) {
        _$this.removeClass('fixed');
        $spacer.hide();
      } else {
        _$this.addClass('fixed');
        $spacer.show();
      }
    };

    $(window).scroll(scrollHandler);

    setTimeout(function () {
      scrollHandler();
    }, 250);
  });

  $('.read-only-input').focus(function () {
    $(this).blur();
  });

  // Shifty eyes for the disguise
  $('.icon-disguise').hover(
    function () {
      var _$this = $(this);
      _$this.addClass('icon-disguise-left');
      shiftyEyesInterval = setInterval(function () {
        _$this.toggleClass('icon-disguise-right');
      }, 1000);
    },
    function () {
      $(this).removeClass('icon-disguise-left').removeClass('icon-disguise-right');
      clearInterval(shiftyEyesInterval);
    },
  );

  // Don't let single word labels wrap on checkboxes or radios
  $('input[type="checkbox"] + label,  input[type="radio"] + label').each(function () {
    if ($(this).text().split(' ').length == 1) {
      $(this).css('white-space', 'nowrap');
    }
  });

  // Set up fixed-position table headers and scrolling table bodies, but not in PDFs
  if ($('.pdfContentWrapper').length == 0) {
    $('.fixedheader')
      .wrap('<div class="fixedheader"></div>')
      .change(function () {
        // The extra width is due to the vertical scroll bar, might be a better way to handle it but don't have mental energy right now
        var $this = $(this),
          $parent = $this.parent();

        var thead = $this.find('thead').get()[0];
        thead.style.setProperty(
          '--fixed-thead-height',
          `${thead.getBoundingClientRect().height}px`,
        );

        if ($parent.closest('main').hasClass('monocle')) return;

        $parent.css('width', 'auto').width($this.outerWidth() + getScrollBarWidth());
        if ($this.css('margin-bottom').substring(0, 1) != '0') {
          $parent.css('margin-bottom', $this.css('margin-bottom'));
          $this.css('margin-bottom', 0);
        }
        if ($this.hasClass('fixedheader-short')) {
          $parent.addClass('fixedheader-short');
        }
      });
    setTimeout(function () {
      $('table.fixedheader').change();
    }, 0);
  }

  if (typeof Storage !== 'undefined') {
    $('form[data-sticky-form-key]').each(function () {
      var frm = $(this),
        formStorageKey = frm.attr('data-sticky-form-key');
      frm.submit(function () {
        sessionStorage.setItem(formStorageKey, $(frm).find(':input[name!=csrfToken]').serialize());
      });
      const triggerChanges = function () {
        frm
          .find('[data-sticky-load-trigger-change]')
          .get()
          .map(e => e.dispatchEvent(new Event('change')));
      };
      if (sessionStorage.getItem(formStorageKey)) {
        if ($(this).data('sticky-form-delay') == true) {
          setTimeout(function () {
            frm.deserialize(sessionStorage.getItem(formStorageKey));
            triggerChanges();
          }, 0);
        } else {
          frm.deserialize(sessionStorage.getItem(formStorageKey));
          triggerChanges();
        }
      } else {
        // Still trigger changes as needed, for post form load
        if ($(this).data('sticky-form-delay') == true) {
          setTimeout(function () {
            triggerChanges();
          }, 0);
        } else {
          triggerChanges();
        }
      }
    });
  }

  /* Nested checkbox lists use tristate magic, but only call it on the most ancestral of the lists */
  $('ul.nested-checkbox-tree').not('ul.nested-checkbox-tree ul.nested-checkbox-tree').tristate();

  /* Tristate checkbox lists that are collapsable should expand by default if they are indeterminate */
  document.querySelectorAll('ul.nested-checkbox-tree li').forEach(li => {
    if (
      li.classList.contains('collapsed') &&
      li.querySelectorAll('input[type=checkbox]:indeterminate').length
    ) {
      li.classList.add('collapsable');
      li.classList.remove('collapsed');
    }
  });

  $('body').on('click', 'a.iframe-modal', function () {
    return iframeModalPopup(this);
  });
  $('.monocle a.iframe-modal').click(function () {
    return iframeModalPopup(this, 640);
  });

  $('body').on('click', 'a.static-win-modal', function () {
    var d = $('#static-modal-dialog');
    if (d.length == 0) {
      d = $('<div id="static-modal-dialog" />');
      $('body').append(d);
    }
    d.load($(this).attr('href') + ' #win_main');
    d.dialog({
      modal: true,
      title: $(this).attr('title'),
      width: 750,
      height: 550,
      classes: window.monocleDialogClasses,
    });
    return false;
  });
});

$.widget('custom.autocompleteWithEmptyValues', $.ui.autocomplete, {
  // Add the item's value as a data attribute on the <li>.
  _renderItem: function (ul, item) {
    //Add the .ui-state-disabled class and don't wrap in <a> if value is empty
    if (item.value === undefined || item.value == '') {
      return $('<li>').addClass('ui-state-disabled').text(item.label).appendTo(ul);
    } else {
      return $('<li>')
        .attr('data-value', item.value)
        .append($('<div class="ui-menu-item-wrapper">').text(item.label))
        .appendTo(ul);
    }
  },
});

$.widget('custom.autocompleteWithEmptyValuesAndArchivedState', $.ui.autocomplete, {
  // Add the item's value as a data attribute on the <li>.
  _renderItem: function (ul, item) {
    //Add the .ui-state-disabled class and don't wrap in <a> if value is empty
    if (item.value === undefined || item.value == '') {
      return $('<li>').addClass('ui-state-disabled').text(item.label).appendTo(ul);
    } else if (item.archived) {
      return $('<li>')
        .attr('data-value', item.value)
        .append($('<div class="ui-menu-item-wrapper">').text(item.label))
        .addClass('ui-autocomplete-archived')
        .appendTo(ul);
    } else {
      return $('<li>')
        .attr('data-value', item.value)
        .append($('<div class="ui-menu-item-wrapper">').text(item.label))
        .appendTo(ul);
    }
  },
});

$('.flash').livequery(function () {
  $(this).css('backgroundColor', 'yellow').animate({ backgroundColor: 'white' }, 1500);
});

/* end jQuery behaviors */

/* This function supports the pop-up text editor that lets us enter long text in small boxes. */
function blurEditor() {
  var editorDiv = document.getElementById('editorDiv');
  var editorTextarea = document.getElementById('editorTextarea');
  var formParent = editorDiv.parentNode;

  editorDiv.style.display = 'none';
  editorDiv.parentNode.removeChild(editorDiv);
  document.getElementById('editorDivStorage').appendChild(editorDiv);

  $(formParent)
    .find('textarea.expandableText')
    .each(function () {
      if ($(this).css('display') == 'none') {
        $(this).val(editorTextarea.value);
        $(this).css('display', '');
      }
    });

  document.getElementById('editorTextarea').value = '';
}

function hide(id) {
  document.getElementById(id).style.display = 'none';
}
function unhide(id) {
  document.getElementById(id).style.display = '';
}

/* These functions support pop-up and "flash" messages */
function flashMsg(message) {
  var objDiv = $('<div>').html(message).addClass('notice bubble flashmsg').attr('id', 'flashmsg');
  $('#main').append(objDiv);
  setTimeout(function () {
    objDiv.hide('slow');
  }, 5000);
}

function hidePop() {
  $('#popmsg').fadeOut('slow', function () {
    $(this).remove();
  });
}

function popMsg(message, lifespan) {
  var objDiv = $('<div>').html(message).addClass('notice bubble popmsg').attr('id', 'popmsg');
  $('#main').append(objDiv);
  objDiv.html(message);
  if (typeof lifespan != 'undefined') setTimeout(hidePop, lifespan);
}

function showLoading() {
  if ($('#popmsg').length > 0) {
    return;
  }
  popMsg(
    'Loading<img id="loading_ellip" src="/images/ellipsis_ani_big.gif" width="20" height="10" />',
  );
  // Forces image to reload to make sure it's animating
  setTimeout(function () {
    $('#loading_ellip').each(function () {
      this.src = this.src + '?random=' + new Date().getTime();
    });
  }, 100);
}

$(document).ready(function () {
  $(document).ajaxStart(showLoading);
  $(document).ajaxStop(hidePop);
});
/* End pop-up and "flash" message functions */

function addClass(element, theClass) {
  if (!element.className.match(new RegExp('(^| )' + theClass + '( |$)'))) {
    element.className = element.className + ' ' + theClass;
  }
}

function removeClass(element, theClass) {
  element.className = element.className.replace(new RegExp('(^| )' + theClass + '( |$)', 'g'), ' ');
}

function commaFormat(value) {
  if (value.replace(/[\s\t\r\n]/g, '').length == 0 || isNaN(value)) return value;
  var nf = window.NumberFormat(value);
  nf.setCurrency(false);
  nf.setPlaces(0);
  return nf.toFormatted();
}

var documentLoaded = false;
function waitForDocumentLoaded(fn) {
  // documentLoaded is set to true at the end of /_footer.cfm
  if (documentLoaded) {
    fn();
    return true;
  } else {
    // Document not loaded yet
    popMsg('Waiting for page to finish loading before processing your request...');
    $(document).ready(function () {
      hidePop();
      fn();
    });
  }
}

/* BEGIN prompt() substitute */

// This is variable for storing callback function
var ae_cb = null;

// this is a simple function-shortcut
// to avoid using lengthy document.getElementById
function ae$(a) {
  return document.getElementById(a);
}

// This is a main ae_prompt function
// it saves function callback
// and sets up dialog
function ae_prompt(cb, q, a) {
  ae_cb = cb;
  ae$('aep_prompt').innerHTML = q;
  ae$('aep_text').value = a;
  ae$('aep_ovrl').style.display = ae$('aep_ww').style.display = '';
  ae$('aep_text').focus();
  ae$('aep_text').select();
}

// This function is called when user presses OK(m=0) or Cancel(m=1) button
// in the dialog. You should not call this function directly.
function ae_clk(m) {
  // hide dialog layers
  ae$('aep_ovrl').style.display = ae$('aep_ww').style.display = 'none';
  if (!m) ae_cb(null);
  // user pressed cancel, call callback with null
  else ae_cb(ae$('aep_text').value); // user pressed OK
}

/* END prompt() substitute */

/* Functions supporting heirarchical checkbox lists */
// A child is defined as having the same ID as its parent, but with "_sometext" appended

// Make child checkboxes force their parents to be checked if the child is
function cbSelectParent() {
  if ($(this).prop('checked')) {
    var idPrefix = $(this)
      .attr('id')
      .replace(/_[^_]+$/, '');
    var jParent = $('#' + idPrefix);
    if (jParent.length == 1 && !jParent.prop('checked')) {
      jParent.prop('checked', true);
      jParent.trigger('change');
    }
  }
}

// Make parent checkboxes deselect their children if they're unchecked
function cbDeselectChildren() {
  if (!$(this).prop('checked')) {
    var parentID = $(this).attr('id');
    $('input[type="checkbox"][id^="' + parentID + '_"]').each(function () {
      $(this).prop('checked', false);
      $(this).trigger('change');
    });
  }
}

/* End functions supporting heirarchical checkbox lists */

document.addEventListener('DOMContentLoaded', function () {
  var makeColsSticky = function (ele) {
    const nCols = ele.dataset.stickyCols;
    let totalWidth = 0;
    for (var cn = 1; cn <= nCols; cn++) {
      var colCells = ele.querySelectorAll(`th:nth-child(${cn}), td:nth-child(${cn})`);
      colCells.forEach(c => {
        c.classList.add('sticky');
        c.style.left = `${totalWidth}px`;

        const styles = getComputedStyle(c);
        c.style.setProperty('--sticky-border-right', styles.borderRightWidth);
        c.style.setProperty('--sticky-border-left', styles.borderLeftWidth);
      });
      totalWidth += ele.querySelector(`th:nth-child(${cn})`).getBoundingClientRect().width;
    }
  };
  document.querySelectorAll('[data-sticky-cols]').forEach(function (ele) {
    if (ele.classList.contains('sortable')) {
      window.addEventListener('load', () => makeColsSticky(ele));
    } else {
      makeColsSticky(ele);
    }
  });

  document
    .querySelectorAll('[data-ajax-button-url]:not([data-ajax-button-manual-attach])')
    .forEach(btn => {
      btn.addEventListener('click', window.handleAjaxButton);
    });
});

window.handleAjaxButton = function (e) {
  const ele = e.target;
  if (Object.hasOwn(ele.dataset || {}, 'ajaxButtonUrl')) {
    const url = ele.dataset.ajaxButtonUrl;
    delete ele.dataset.ajaxButtonUrl;
    fetch(url, { method: 'POST' })
      .then(response => response.json())
      .then(data => {
        ele.textContent = data.msg;
      });
  }
};

// Create a live region for screen readers
// Ex: announce the start of a download
window.liveAnnouncement = function (message) {
  console.log(message);
  const liveRegion = document.createElement('div');
  liveRegion.setAttribute('aria-live', 'polite');
  liveRegion.setAttribute('role', 'status');
  liveRegion.setAttribute('class', 'sr-only');
  document.body.appendChild(liveRegion);
  setTimeout(() => {
    liveRegion.textContent = message;
    setTimeout(() => document.body.removeChild(liveRegion), 3000);
  }, 100);
};

window.obj = obj;
window.makeCollapsable = makeCollapsable;
window.fixVertTextContainerSize = fixVertTextContainerSize;
window.blurEditor = blurEditor;
window.hide = hide;
window.unhide = unhide;
window.flashMsg = flashMsg;
window.hidePop = hidePop;
window.popMsg = popMsg;
window.showLoading = showLoading;
window.addClass = addClass;
window.removeClass = removeClass;
window.commaFormat = commaFormat;
window.documentLoaded = documentLoaded;
window.waitForDocumentLoaded = waitForDocumentLoaded;
window.ae_cb = ae_cb;
window.ae$ = ae$;
window.ae_prompt = ae_prompt;
window.ae_clk = ae_clk;
window.cbSelectParent = cbSelectParent;
window.cbDeselectChildren = cbDeselectChildren;
