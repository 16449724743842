import {
  BarChart,
  BaseHistogramChart,
  BreakdownChart,
  FloatingStackedBarChart,
  LinearScaleChart,
  options as chartOptions,
  RatioChart,
  StackedBarChart,
  PieChart,
  getStandardCannedReportParameters,
} from './charting/';

(function (charting, $) {
  var averageCharacterWidth = 7;
  $(document).ready(function () {
    averageCharacterWidth = calculateAverageCharacterWidth();
  });

  charting.BarChart = function (wrapper, barSeries, pntSeries, lineSeries, options) {
    return BarChart.call(
      this,
      wrapper,
      barSeries,
      pntSeries,
      lineSeries,
      options,
      averageCharacterWidth,
    );
  };

  charting.FloatingStackedBarChart = function (wrapper, barSeries, pntSeries, lineSeries, options) {
    return FloatingStackedBarChart.call(
      this,
      wrapper,
      barSeries,
      pntSeries,
      lineSeries,
      options,
      averageCharacterWidth,
    );
  };

  charting.StackedBarChart = function (wrapper, barSeries, pntSeries, lineSeries, options) {
    return StackedBarChart.call(
      this,
      wrapper,
      barSeries,
      pntSeries,
      lineSeries,
      options,
      averageCharacterWidth,
    );
  };

  charting.BaseHistogramChart = function (
    wrapper,
    bins,
    aggregates,
    addlLines,
    options,
    binLabelOverrides,
  ) {
    return BaseHistogramChart.call(
      this,
      wrapper,
      bins,
      aggregates,
      addlLines,
      options,
      binLabelOverrides,
      averageCharacterWidth,
    );
  };
  charting.PieChart = function (wrapper, pieSeries, options) {
    return PieChart.call(this, wrapper, pieSeries, options, averageCharacterWidth);
  };
  charting.LinearScaleChart = function (wrapper, dataSeries, colorSeries, options) {
    return LinearScaleChart.call(
      this,
      wrapper,
      dataSeries,
      colorSeries,
      options,
      averageCharacterWidth,
    );
  };
  charting.BreakdownChart = function (wrapper, dataSeries, colorSeries, options) {
    return BreakdownChart.call(
      this,
      wrapper,
      dataSeries,
      colorSeries,
      options,
      averageCharacterWidth,
    );
  };
  charting.RatioChart = function (wrapper, dataSeries, colorSeries, ticks, options) {
    return RatioChart.call(
      this,
      wrapper,
      dataSeries,
      colorSeries,
      ticks,
      options,
      averageCharacterWidth,
    );
  };

  charting.Options = chartOptions;

  charting.Series = function (name, data, labels) {
    this.name = name;
    this.data = data;
    this.labels = labels;
    this.hasLabels = function () {
      return typeof this.labels != 'undefined';
    };
    this.longestLabel = function () {
      return this.hasLabels() ? _longestLabel() : 0;
    };
    this.len = function () {
      return this.data.length;
    };

    function _longestLabel() {
      var len = 0;
      $.each(labels, function (idx, lbl) {
        len = Math.max(len, lbl.length);
      });
      return len;
    }

    return this;
  };

  function calculateAverageCharacterWidth() {
    var $ele = $('<div>ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz</div>').hide();
    $('body').append($ele);
    var totalWidth = $ele.width();
    $ele.remove();
    return totalWidth / 52;
  }

  /* Multiple places use this to get the "settings" for charts */
  charting.getStandardCannedReportParameters = getStandardCannedReportParameters;
})((window.charting = window.charting || {}), jQuery);
