import { toPng } from 'html-to-image'; // Generates PNG from HTML node
import FileSaver from 'file-saver'; // Serves generated PNG as download

import d3 from './d3';
import removeExcessCharacters from './removeExcessCharacters';

const saveChartAsImage = function (selector, linkSelector, averageCharacterWidth, addTitle = true) {
  const originalTitle = $(linkSelector).text();
  // Update UI
  $(linkSelector).text('Generating image...');
  const opts = $(selector).data('options');
  var $container;

  // Capture surrounding container as well as chart
  if (opts.getCaptureContainerInDownload()) {
    $container = captureSurroundingElements(selector, linkSelector);
  } else {
    $container = $('<div>');
  }

  // Add inline styling to chart
  const $originalSVG = $(selector + ' svg');

  const $chart = $originalSVG.clone();
  $chart.attr('xmlns', 'http://www.w3.org/2000/svg');
  $chart.attr('xmlns:xlink', 'http://www.w3.org/1999/xlink');
  $chart.attr('height', parseInt($chart.attr('height')) + 20); // Add padding at the end so the chart isn't cut off

  // Add chart options as an annotation
  if (opts.getIncludeAnnotationsInDownload()) {
    addAnnotationsToChart($chart, opts);
  }

  $container.append($chart);
  $container.css('background-color', $('body').css('background-color'));

  // Append chart to document and generate PNG
  const tempChartID = 'temp-chart-for-download';
  $('body').append(
    $('<div>').attr('id', tempChartID).css('width', 'fit-content').append($container),
  );
  const chartNode = document.getElementById(tempChartID);

  // Get chart resolution and scale up
  const targetHeight = 2160;
  const targetWidth = (targetHeight / chartNode.offsetHeight) * chartNode.offsetWidth;

  // Add a delay for any inserted images to load
  setTimeout(function () {
    toPng(chartNode, {
      canvasWidth: targetWidth,
      canvasHeight: targetHeight,
    }).then(function (dataUrl) {
      FileSaver.saveAs(dataUrl, 'chart.png');
      // Remove appended chart and update UI
      $('#' + tempChartID).remove();
      $(linkSelector).text(originalTitle);
    });
  }, 100);

  function addAnnotationsToChart($chart, opts) {
    var numSettings = opts.settingKeys().length;
    var d3Chart = d3.select($chart.get(0));
    var chartWidth = getDimension($chart, 'width');
    var heightChange = 0;
    var titleHeight = 0;
    var chartXOffset = 0;
    // When there is more than just the chart, give the chart a little margin
    if ((addTitle && opts.hasTitle()) || numSettings > 0) {
      chartXOffset = 20;
      chartWidth += 40;
      $chart.css('width', chartWidth);
      $chart.attr('width', chartWidth);
    }
    if (addTitle && opts.hasTitle()) {
      var charsPerRowInTitle = Math.floor((chartWidth - 20) / averageCharacterWidth);
      var titleRows = wrapTextToLength(opts.getTitle(), charsPerRowInTitle);
      titleRows.forEach(function (titleRow, idx) {
        d3Chart
          .append('text')
          .attr('x', 10)
          .attr('y', 20 * (idx + 1))
          .text(titleRow)
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .attr('class', 'chart-title');
        heightChange += 20;
        titleHeight += 20;
      });
    }
    if (numSettings) {
      var settingsContainer = d3Chart.append('g').attr('class', 'settings');
      var longestKeyText = 0;
      var longestValText = 0;
      var wrappedRows = 0;
      var longestKeyTextUnwrapped = 0;
      var i, key;
      for (i = 0; i < numSettings; i++) {
        key = removeExcessCharacters(opts.settingKeys()[i]);
        longestKeyTextUnwrapped = Math.max(key.length, longestKeyTextUnwrapped);
      }
      var settingsBoxIndent = opts.hasTitle() ? 20 : 0;
      var settingsValueXOffset =
        settingsBoxIndent + 20 + longestKeyTextUnwrapped * averageCharacterWidth;
      var wrapSettingKeysToNChars = 0;
      if (settingsValueXOffset > chartWidth / 2) {
        settingsValueXOffset = chartWidth / 2;
        wrapSettingKeysToNChars = Math.floor(
          (settingsValueXOffset - 20 - settingsBoxIndent) / averageCharacterWidth,
        );
      }
      var charactersPerRowInValue = Math.floor(
        (chartWidth - settingsValueXOffset - 10) / averageCharacterWidth,
      );
      for (i = 0; i < numSettings; i++) {
        key = removeExcessCharacters(opts.settingKeys()[i]);
        var val = removeExcessCharacters(opts.getSettings()[key]);
        key += ':';
        var keyRows =
          wrapSettingKeysToNChars > 0 ? wrapTextToLength(key, wrapSettingKeysToNChars) : [key];
        var valueRows = wrapTextToLength(val, charactersPerRowInValue);
        keyRows.forEach(function (keyRow, idx) {
          settingsContainer
            .append('text')
            .text(keyRow)
            .attr('x', 10 + settingsBoxIndent)
            .attr('y', 20 + titleHeight)
            .attr('dy', 1.7 * i + 1.2 * (wrappedRows + idx) + 'em');
          longestKeyText = Math.max(keyRow.length, longestKeyText);
        });
        valueRows.forEach(function (valRow, idx) {
          longestValText = Math.max(valRow.length, longestValText);
          settingsContainer
            .append('text')
            .text(valRow)
            .attr('x', settingsValueXOffset)
            .attr('y', 20 + titleHeight)
            .attr('dy', 1.7 * i + 1.2 * (wrappedRows + idx) + 'em')
            .attr('class', 'key-value');
        });
        var nNewRows = Math.max(keyRows.length, valueRows.length);
        wrappedRows += nNewRows - 1;
        heightChange += 3 + nNewRows * 19;
      }
    }
    alterTransformTranslate($chart.find('g.chart-wrapper'), heightChange, chartXOffset);

    var currentHeight = getDimension($chart, 'height');

    if (opts.hasFooter()) {
      $.each(opts.getFooter().split(/<br ?\/?>/), function () {
        heightChange += 15;
        d3Chart
          .append('text')
          .text(this)
          .attr('x', 10)
          .attr('y', currentHeight + heightChange);
      });
      heightChange += 16;
    }

    var newHeight = currentHeight + heightChange;

    $chart.css('height', newHeight);
    $chart.attr('height', newHeight);

    var requiredWidth = (longestKeyText + longestValText) * averageCharacterWidth + 30;
    if (chartWidth < requiredWidth) {
      $chart.css('width', requiredWidth);
      $chart.attr('width', requiredWidth);
    }
  }

  function alterTransformTranslate($ele, y, x) {
    var tform = $ele.attr('transform');
    var pts = tform.split('translate(');
    var newTForm = pts[0] + 'translate(';
    var tFormX = pts[1].split(/[, ]/)[0];
    var tFormY = pts[1].split(/[, ]/)[1].split(')')[0];
    var after = pts[1].split(')').slice(1).join(')');

    tFormX = parseInt(tFormX) + x;
    tFormY = parseInt(tFormY) + y;

    newTForm += tFormX + ',';
    newTForm += tFormY + ')' + after;
    $ele.attr('transform', newTForm);
  }

  function captureSurroundingElements(selector, linkSelector) {
    const $container = $(selector).parent().clone();
    $container.find('svg').remove();
    $container.find(linkSelector).remove();
    return $container;
  }

  function getDimension(chart, dim) {
    var val = parseInt(chart.css(dim));
    if (val == 0) {
      val = parseInt(chart.attr(dim));
    }
    return val;
  }

  function wrapTextToLength(val, nChars) {
    var valueRows = [val];
    while (valueRows.slice(-1)[0].length > nChars) {
      var row = valueRows.pop();
      var splitPos = row.lastIndexOf(' ', nChars);
      if (splitPos == -1 || splitPos == row.length) {
        valueRows.push(row);
        break;
      } else {
        valueRows.push(row.slice(0, splitPos));
        valueRows.push(row.slice(splitPos + 1));
      }
    }
    return valueRows;
  }
};

export default saveChartAsImage;
