document.addEventListener('DOMContentLoaded', function () {
  const monoclePage = document.querySelector('main.monocle');

  function collapseAll() {
    document.querySelectorAll('.collapsable').forEach(e => {
      e.className = 'collapsed';
      for (const child of e.children) {
        if (child.classList.contains('collapsable-toggle')) {
          child.setAttribute('aria-expanded', 'false');
          break;
        }
      }
    });
    stickyManager.updateAll(false);
    return false;
  }

  function expandAll() {
    document.querySelectorAll('.collapsed').forEach(e => {
      e.className = 'collapsable';
      for (const child of e.children) {
        if (child.classList.contains('collapsable-toggle')) {
          child.setAttribute('aria-expanded', 'true');
          break;
        }
      }
    });
    stickyManager.updateAll(true);
    return false;
  }

  function toggleCollapse(e) {
    e.stopPropagation();
    const wrapper = e.target.closest('.collapsed,.collapsable');
    // Find toggle button
    const toggleButton = wrapper.querySelector(':scope .collapsable-toggle');

    if (wrapper.classList.contains('collapsed')) {
      wrapper.classList.add('collapsable');
      wrapper.classList.remove('collapsed');
      if (toggleButton) {
        toggleButton.setAttribute('aria-expanded', 'true');
      }
    } else if (wrapper.classList.contains('collapsable')) {
      wrapper.classList.add('collapsed');
      wrapper.classList.remove('collapsable');
      if (toggleButton) {
        toggleButton.setAttribute('aria-expanded', 'false');
      }
    }

    stickyManager.updateStickyState(wrapper);
  }

  function refreshCollapsableTreeListeners() {
    monoclePage
      .querySelectorAll('.collapsable-icon, .collapsed-icon, .collapsable-toggle')
      .forEach(el => {
        el.removeEventListener('click');
        el.addEventListener('click', toggleCollapse);
        el.removeEventListener('keydown');
        el.addEventListener('keydown', event => {
          if (event.key === 'Enter' || event.key === ' ') {
            // Space or Enter key
            event.preventDefault(); // Prevent the default action to stop scrolling when pressing Space
            toggleCollapse(event);
          }
        });
      });

    monoclePage.querySelectorAll('.collapse-all').forEach(el => {
      el.removeEventListener('click');
      el.addEventListener('click', collapseAll);
      el.removeEventListener('keydown');
      el.addEventListener('keydown', event => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          collapseAll();
        }
      });
    });

    monoclePage.querySelectorAll('.expand-all').forEach(el => {
      el.removeEventListener('click');
      el.addEventListener('click', expandAll);
      el.removeEventListener('keydown');
      el.addEventListener('keydown', event => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          expandAll();
        }
      });
    });
  }

  const stickyManager = (function () {
    var storageKey = function () {
      return 'collapse-open';
    };

    var getKey = function (e) {
      return e.dataset.toggleSticky || '';
    };

    var getStorageValue = function (key) {
      var raw = localStorage.getItem(storageKey());
      if (raw == undefined || raw.length == 0) {
        raw = '{}';
      }
      var data = JSON.parse(raw);
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        return data[key];
      } else {
        return;
      }
    };

    var setStorageValue = function (key, value) {
      var raw = localStorage.getItem(storageKey());
      if (raw == undefined || raw.length == 0) {
        raw = '{}';
      }
      var data = JSON.parse(raw);
      data[key] = value;
      localStorage.setItem(storageKey(), JSON.stringify(data));
    };

    return {
      setStartingStates: function () {
        document.querySelectorAll('[data-toggle-sticky]').forEach(function (el) {
          const key = getKey(el);
          if (key.length > 0) {
            const isOpen = getStorageValue(key);
            if (isOpen !== undefined) {
              if (isOpen) {
                el.classList.add('collapsable');
                el.classList.remove('collapsed');
              } else if (!isOpen) {
                el.classList.add('collapsed');
                el.classList.remove('collapsable');
              }
            }
          }
        });
      },
      updateStickyState: function (ele) {
        const key = getKey(ele);
        if (key.length > 0) {
          setStorageValue(key, ele.classList.contains('collapsable'));
        }
      },
      updateAll: function (isOpen) {
        document.querySelectorAll('[data-toggle-sticky]').forEach(function (el) {
          const key = getKey(el);
          if (key.length > 0) {
            setStorageValue(key, isOpen);
          }
        });
      },
    };
  })();

  // Only "install" this on Monocle pages
  if (monoclePage != null) {
    refreshCollapsableTreeListeners();
    // Set aria-expanded on collapsable-toggle buttons
    document.querySelectorAll('.collapsable-toggle').forEach(function (el) {
      el.setAttribute('aria-expanded', el.closest('.collapsable') !== null);
    });
    stickyManager.setStartingStates();
  }

  // React relies on this being present for the styleguide, and one other place in code does as well...
  window.refreshCollapsableTreeListeners = refreshCollapsableTreeListeners;
});
